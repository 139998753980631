<script setup lang="ts">
const { t } = useI18n()
const total = ref(0)
const isLoading = ref(false)

useHead({
  title: computed(() => t('layouts.title', [t('pages.title.index')])),
})
</script>

<template>
  <div class="mx-auto max-w-3xl px-4 lg:max-w-[88rem] md:max-w-4xl lg:px6 sm:px-6 sm:px4">
    <div class="mb4">
      <ProductsGrid
        @total="(v) => (total = v)"
        @loading="(loading) => (isLoading = loading)"
      />
    </div>
  </div>
</template>
